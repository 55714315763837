<template>
  <main class="content container">
    <Loader v-if="loading" />
    <article v-else class="order-success">
      <h1 class="page-title text-center">Vielen Dank für Ihren Einkauf!</h1>
      <h2 class="title-secondary">
        Ihre Bestellung wurde am {{ nowData }} an unsere Verkaufsabteilung weitergeleitet.
        In Kürze erhalten Sie eine technische Zeichnung sowie eine Rechnung für die Anzahlung.
        Bei Fragen oder Änderungswünschen stehen wir Ihnen gerne zur Verfügung.
      </h2>
      <div class="order-success__number title-secondary bold-weight">
        <span>Bestellnummer:</span>
        <span class="link">#{{ orderId }}</span>
      </div>
      <router-link :to="{ name: 'home' }" class="btn btn-primary min-width-170">Homepage</router-link>
    </article>
    <InfoBox />
  </main>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import InfoBox from './cart/infoBox'
import Loader from '@/components/Loader'
import moment from 'moment'
export default {
  components: {
    InfoBox,
    Loader
  },
  setup () {
    useMeta({
      title: 'Vielen Dank für Ihren Einkauf!'
    })
    const store = useStore()
    const orderId = computed(() => {
      return store.getters['checkout/orderId']
    })
    const loading = computed(() => {
      return store.getters['checkout/orderSending']
    })
    const nowData = computed(() => {
      return moment().format('DD. MMM YYYY') + ' um ' + moment().format('HH:mm')
    })
    return {
      orderId,
      loading,
      nowData
    }
  }
}
</script>
